/** 卖出管理 */
<template>
  <div class="my-sell">
    <router-link class="sell-btn" :to="{ path: `/my/trading/product`, query: { tab: activeTab } }">
      <el-button class="mb-30" type="primary" size="small">发布卖品</el-button>
    </router-link>
    <el-tabs v-model="activeTab">
      <el-tab-pane label="专利" name="Patent">
        <PatentList />
      </el-tab-pane>
      <el-tab-pane label="商标" name="Trademark">
        <TrademarkList />
      </el-tab-pane>
      <el-tab-pane label="资产包" name="Pack">
        <PackList />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import PatentList from './components/sell_patent_list.vue'
import TrademarkList from './components/sell_trademark_list.vue'
import PackList from './components/sell_pack_list.vue'
export default {
  name: 'MyTradingSell',
  components: {
    PatentList,
    TrademarkList,
    PackList
  },
  data () {
    return {
      activeTab: this.$route.params.tab || 'Patent',
    }
  },
  activated () {
    this.activeTab = this.$route.params.tab || 'Patent'
  }
}
</script>
<style lang="scss" scoped>
.my-sell {
  position: relative;

  .sell-btn {
    position: absolute;
    right: 0;
    z-index: 1;
  }
}

::v-deep .el-tabs {
  .el-tabs__nav-wrap {
    &::after {
      display: none;
    }
  }

  .el-tabs__active-bar {
    height: 3px;
  }

  .el-tabs__item {
    font-size: 20px;
    font-weight: bold;
  }
}
</style>